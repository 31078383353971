.Tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;
	margin: 0;
	padding: 0;
	list-style: none;
}

.Tag {
	display: inline-block;
	font-size: 0.875rem;
	padding: 0.1em 0.5em;
	text-decoration: none;
	border: var(--border) var(--color-outline);
	border-radius: var(--border-radius);
}

.Tag:not(.Tag--all)::before {
	content: "#";
}

a.Tag {
	color: var(--color-heading);
}

a.Tag:hover {
	text-decoration: underline;
}

span.Tag {
	border-color: var(--color-tag-bg-active);
	color: var(--color-tag-active);
	background: var(--color-tag-bg-active);
}
