@import "./common/elements.css";
@import "./common/code.css";
@import "./common/content.css";
@import "./common/header.css";
@import "./common/header-icon.css";
@import "./common/nav.css";
@import "./common/note.css";
@import "./common/settings.css";
@import "./common/share.css";
@import "./common/tags.css";
@import "./common/theme-toggle.css";
@import "./common/tokens.css";
@import "./common/utils.css";

* {
	box-sizing: border-box;
}
