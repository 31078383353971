@import url("./code-light.css");
@import url("./code-dark.css");

pre,
:not(pre) > code {
	background: var(--color-bg-code);
	border: var(--border) var(--color-outline);
	border-radius: var(--border-radius);
}

pre {
	padding: 1em;
}

:not(pre) > code {
	padding: 0.1em 0.25em;
}

code {
	font-family: Menlo, monospace;
	font-size: 0.8125em;
}

pre[class*="language-"] {
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

:not(pre) > code[class*="language-"] {
	white-space: normal;
	border-radius: var(--border-radius);
	padding: 0.1em;
}

pre[class*="language-"] {
	overflow: auto;
	position: relative;
	padding: 1.25em 1em;
}

[class*="language-"] .namespace {
	opacity: 0.7;
}

.token.id {
	font-weight: bold;
}

.token.important {
	font-weight: bold;
}
