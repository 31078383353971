html {
	--true: ;
	--false: initial;

	--light: var(--true);

	--color-h: 204;
	--color-s: 30%;

	--color-scheme: var(--light) light;
	--c-l-bg-code: var(--light) hsl(var(--color-h), var(--color-s), 96%);
	--c-l-bg-header: var(--light) hsl(var(--color-h), var(--color-s), 10%);
	--c-l-bg: var(--light) hsl(var(--color-h), var(--color-s), 100%);
	--c-l-outline: var(--light) hsl(var(--color-h), var(--color-s), 82%);
	--c-l-secondary: var(--light) hsl(var(--color-h), var(--color-s), 45%);
	--c-l-tag-active: var(--light) hsl(var(--color-h), var(--color-s), 100%);
	--c-l-text: var(--light) hsl(var(--color-h), var(--color-s), 20%);
	--c-l-theme-icon-active: var(--light)
		hsl(var(--color-h), var(--color-s), 100%);
	--c-l-heading: var(--light) hsl(200, 65%, 20%);
	--c-l-theme-icon: var(--light) hsl(200, 65%, 20%);
	--c-l-tag-bg-active: var(--light) hsl(200, 65%, 20%);
	--c-l-link: var(--light) hsl(320, 80%, 25%);

	--c-d-scheme: dark;
	--c-d-bg-code: hsl(var(--color-h), var(--color-s), 14%);
	--c-d-bg-header: hsl(var(--color-h), var(--color-s), 10%);
	--c-d-bg: hsl(var(--color-h), var(--color-s), 12%);
	--c-d-outline: hsl(var(--color-h), var(--color-s), 24%);
	--c-d-secondary: hsl(var(--color-h), var(--color-s), 75%);
	--c-d-tag-active: hsl(var(--color-h), var(--color-s), 10%);
	--c-d-text: hsl(var(--color-h), var(--color-s), 92%);
	--c-d-theme-icon-active: hsl(var(--color-h), var(--color-s), 10%);
	--c-d-heading: hsl(200, 50%, 80%);
	--c-d-theme-icon: hsl(200, 50%, 80%);
	--c-d-tag-bg-active: hsl(200, 50%, 80%);
	--c-d-link: hsl(320, 40%, 75%);

	--color-bg-code: var(--c-l-bg-code, var(--c-d-bg-code));
	--color-bg-header: var(--c-l-bg-header, var(--c-d-bg-header));
	--color-bg: var(--c-l-bg, var(--c-d-bg));
	--color-outline: var(--c-l-outline, var(--c-d-outline));
	--color-secondary: var(--c-l-secondary, var(--c-d-secondary));
	--color-tag-active: var(--c-l-tag-active, var(--c-d-tag-active));
	--color-text: var(--c-l-text, var(--c-d-text));
	--color-theme-icon-active: var(
		--c-l-theme-icon-active,
		var(--c-d-theme-icon-active)
	);
	--color-heading: var(--c-l-heading, var(--c-d-heading));
	--color-theme-icon: var(--c-l-theme-icon, var(--c-d-theme-icon));
	--color-tag-bg-active: var(--c-l-tag-bg-active, var(--c-d-tag-bg-active));
	--color-link: var(--c-l-link, var(--c-d-link));

	--color-scheme: var(--color-scheme, var(--c-d-scheme));

	--spacing: clamp(1rem, 3vw, 3rem);
	--spacing-l: clamp(2rem, 3vw, 3rem);
	--divider: 0.0625rem solid;
	--border: 0.0625rem solid;
	--border-radius: 0.25rem;

	--header-main-gap: 1rem;
	--header-main-color: #fff;
	--header-main-line-height: 1.2;
	--header-main-font-weight: 600;
	--header-main-text-decoration: none;

	accent-color: var(--color-link);
	color-scheme: var(--color-scheme);
}

.theme-light {
	--light: var(--true);
}

html:has([name="theme"][value="light"]:checked) {
	--light: var(--true);
}

@media (prefers-color-scheme: dark) {
	html {
		--light: var(--false);
	}
}

.theme-dark {
	--light: var(--false);
}

html:has([name="theme"][value="dark"]:checked) {
	--light: var(--false);
}
