.Note-date {
	font-size: 0.875em;
}

.Note-title {
	margin-block-start: 1rem;
}

.Note-title :is(h1, h2) {
	margin-block: 0;
	line-height: 1.5;
}

.Note h1 {
	font-size: 2em;
}

.Note h2:not(:first-child) {
	margin-block-start: 1.5em;
}

.Note-footer {
	font-size: 0.875em;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}

.Note-source {
	margin: 0;
}
