.Nav {
	margin-inline-start: -0.5rem;
}

.Nav-list {
	display: flex;
	gap: 1.5rem;
	list-style: none;
	padding: 0.5rem 0;
	margin: 0;
}

.Nav-link {
	display: block;
	text-decoration: none;
	padding-inline: 0.5rem;
}

.Nav-link[aria-current="true"] {
	font-weight: bold;
	text-decoration: underline;
}

.Nav-link:hover {
	text-decoration: underline;
}
