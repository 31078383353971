body {
	background: var(--color-bg);
	color: var(--color-text);
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	line-height: 1.75;
	margin: 0;
	min-block-size: 100vb;
	min-block-size: 100dvb;
	display: flex;
	flex-direction: column;
}

dl {
	display: grid;
	grid-template-columns: auto 1fr;
}

dt {
	font-weight: 600;
	color: var(--color-secondary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--color-heading);
}

article {
	overflow-wrap: break-word;
}

a {
	border-radius: var(--border-radius);
	color: var(--color-link);
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}

img {
	display: block;
	max-inline-size: 100%;
	block-size: auto;
	border-radius: 0.5rem;
}

blockquote {
	color: var(--color-secondary);
	position: relative;
	margin-inline-start: 4em;
}

blockquote::before {
	--size: 2em;

	display: block;
	content: "";
	inline-size: var(--size);
	block-size: var(--size);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='500' height='1000'%3E%3Cpath d='M369.5 121.2C184 175.2 10 375.2 10 633.4c0 159.5 101.5 275.5 226.1 275.5 130.5 0 197.2-98.6 197.2-188.5 0-104.4-72.5-185.5-179.8-185.5-31.8 0-63.8 8.8-81.2 23.2 0-127.6 101.5-278.4 258-356.6l-60.8-80.3'/%3E%3C/svg%3E");
	background-size: 50% 100%;
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: calc(100% + 0.5rem);
	opacity: 0.15;
}

@media (prefers-color-scheme: dark) {
	blockquote::before {
		filter: invert(1);
	}
}

.theme-dark blockquote::before {
	filter: invert(1);
}

pre {
	margin-block: 1.5em;
}
