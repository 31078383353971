@media (prefers-color-scheme: dark) {
	.language-css > code,
	.language-sass > code,
	.language-scss > code,
	.token.number,
	.token.unit {
		color: #fd9170;
	}

	.token.constant,
	.token.function,
	.token.id,
	.token.important,
	.token.keyword,
	.token.atrule,
	.token.boolean,
	.token.symbol {
		color: #c792ea;
	}

	.token.attr-name,
	.token.builtin,
	.token.class {
		color: #ffcb6b;
	}

	.token.attr-value,
	.token.attribute,
	.token.pseudo-class,
	.token.pseudo-element,
	.token.string {
		color: #a5e844;
	}

	.token.cdata,
	.token.char,
	.token.inserted,
	.token.property {
		color: #80cbc4;
	}

	.token.comment,
	.token.doctype,
	.token.prolog {
		color: #868e99;
	}

	.token.deleted,
	.token.entity,
	.token.selector,
	.token.tag,
	.token.url,
	.token.variable {
		color: #ff6666;
	}

	.token.operator,
	.token.punctuation {
		color: #89ddff;
	}

	.token.hexcode,
	.token.regex,
	.token.class-name {
		color: #f2ff00;
	}
}

.theme-dark .language-css > code,
.theme-dark .language-sass > code,
.theme-dark .language-scss > code,
.theme-dark .token.number,
.theme-dark .token.unit {
	color: #fd9170;
}

.theme-dark .token.constant,
.theme-dark .token.function,
.theme-dark .token.id,
.theme-dark .token.important,
.theme-dark .token.keyword,
.theme-dark .token.atrule,
.theme-dark .token.boolean,
.theme-dark .token.symbol {
	color: #c792ea;
}

.theme-dark .token.attr-name,
.theme-dark .token.builtin,
.theme-dark .token.class {
	color: #ffcb6b;
}

.theme-dark .token.attr-value,
.theme-dark .token.attribute,
.theme-dark .token.pseudo-class,
.theme-dark .token.pseudo-element,
.theme-dark .token.string {
	color: #a5e844;
}

.theme-dark .token.cdata,
.theme-dark .token.char,
.theme-dark .token.inserted,
.theme-dark .token.property {
	color: #80cbc4;
}

.theme-dark .token.comment,
.theme-dark .token.doctype,
.theme-dark .token.prolog {
	color: #868e99;
}

.theme-dark .token.deleted,
.theme-dark .token.entity,
.theme-dark .token.selector,
.theme-dark .token.tag,
.theme-dark .token.url,
.theme-dark .token.variable {
	color: #ff6666;
}

.theme-dark .token.operator,
.theme-dark .token.punctuation {
	color: #89ddff;
}

.theme-dark .token.hexcode,
.theme-dark .token.regex,
.theme-dark .token.class-name {
	color: #f2ff00;
}

html:has([name="theme"][value="dark"]:checked) .language-css > code,
html:has([name="theme"][value="dark"]:checked) .language-sass > code,
html:has([name="theme"][value="dark"]:checked) .language-scss > code,
html:has([name="theme"][value="dark"]:checked) .token.number,
html:has([name="theme"][value="dark"]:checked) .token.unit {
	color: #fd9170;
}

html:has([name="theme"][value="dark"]:checked) .token.constant,
html:has([name="theme"][value="dark"]:checked) .token.function,
html:has([name="theme"][value="dark"]:checked) .token.id,
html:has([name="theme"][value="dark"]:checked) .token.important,
html:has([name="theme"][value="dark"]:checked) .token.keyword,
html:has([name="theme"][value="dark"]:checked) .token.atrule,
html:has([name="theme"][value="dark"]:checked) .token.boolean,
html:has([name="theme"][value="dark"]:checked) .token.symbol {
	color: #c792ea;
}

html:has([name="theme"][value="dark"]:checked) .token.attr-name,
html:has([name="theme"][value="dark"]:checked) .token.builtin,
html:has([name="theme"][value="dark"]:checked) .token.class {
	color: #ffcb6b;
}

html:has([name="theme"][value="dark"]:checked) .token.attr-value,
html:has([name="theme"][value="dark"]:checked) .token.attribute,
html:has([name="theme"][value="dark"]:checked) .token.pseudo-class,
html:has([name="theme"][value="dark"]:checked) .token.pseudo-element,
html:has([name="theme"][value="dark"]:checked) .token.string {
	color: #a5e844;
}

html:has([name="theme"][value="dark"]:checked) .token.cdata,
html:has([name="theme"][value="dark"]:checked) .token.char,
html:has([name="theme"][value="dark"]:checked) .token.inserted,
html:has([name="theme"][value="dark"]:checked) .token.property {
	color: #80cbc4;
}

html:has([name="theme"][value="dark"]:checked) .token.comment,
html:has([name="theme"][value="dark"]:checked) .token.doctype,
html:has([name="theme"][value="dark"]:checked) .token.prolog {
	color: #868e99;
}

html:has([name="theme"][value="dark"]:checked) .token.deleted,
html:has([name="theme"][value="dark"]:checked) .token.entity,
html:has([name="theme"][value="dark"]:checked) .token.selector,
html:has([name="theme"][value="dark"]:checked) .token.tag,
html:has([name="theme"][value="dark"]:checked) .token.url,
html:has([name="theme"][value="dark"]:checked) .token.variable {
	color: #ff6666;
}

html:has([name="theme"][value="dark"]:checked) .token.operator,
html:has([name="theme"][value="dark"]:checked) .token.punctuation {
	color: #89ddff;
}

html:has([name="theme"][value="dark"]:checked) .token.hexcode,
html:has([name="theme"][value="dark"]:checked) .token.regex,
html:has([name="theme"][value="dark"]:checked) .token.class-name {
	color: #f2ff00;
}
