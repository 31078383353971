.Header {
	--light: var(--false);
	--color-heading: var(--c-d-heading);
	--color-link: var(--c-d-link);
	--color-text: var(--c-d-text);
	--color-theme-icon-active: var(--c-d-theme-icon-active);
	--color-theme-icon: var(--c-d-theme-icon);

	background: var(--color-bg-header);
	display: flex;
	align-items: center;
	gap: 1rem;
	justify-content: space-between;
	padding-block: 0.75rem;
	border-block-end: var(--divider) var(--color-outline);
	position: sticky;
	inset-block-start: 0;
	z-index: 1;
}

.Header-name {
	font-size: 1rem;
	margin: 0;
}

.Header-link {
	display: flex;
	align-items: center;
	gap: var(--header-main-gap);
	line-height: var(--header-main-line-height);
	font-weight: var(--header-main-font-weight);
	text-decoration: var(--header-main-text-decoration);
	color: var(--header-main-color);
	padding-inline-end: var(--header-main-gap);
}

.Header-link:hover {
	text-decoration: underline;
}

.Header-link picture {
	flex-shrink: 0;
}

.Header-link img {
	border-radius: var(--border-radius);
}
