.HeaderIcon {
	--size: 1.5rem;

	appearance: none;
	border: none;
	background: none;
	padding: 0;
	display: flex;
	flex-shrink: 0;
	inline-size: var(--size);
	block-size: var(--size);
	stroke: var(--color-heading);
}
