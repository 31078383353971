mg-share {
	display: flex;
	justify-content: flex-end;
	color: var(--color-secondary);
	flex: 1;
}

mg-share button {
	color: inherit;
	font: inherit;
	appearance: none;
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;
	text-decoration: underline;
}
