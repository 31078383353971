.ThemeToggle {
	align-items: center;
	gap: 0.5rem;
	display: flex;
	border: none;
	padding: 0;
	margin-inline: 0;
}

.ThemeToggle-wrapper {
	display: flex;
	gap: 0.5rem;
}

.ThemeToggle label {
	color: var(--color-heading);
	border-radius: var(--border-radius);
	padding: 0.25em;
	color: var(--color-theme-icon);
}

.ThemeToggle input:checked + label {
	background: var(--color-heading);
	color: var(--color-theme-icon-active);
}

:is(a, input, button):focus-visible,
.ThemeToggle input:focus + label {
	outline: 0.125rem solid var(--color-link);
	outline-offset: 0.125rem;
}

.ThemeToggle-icon {
	--size: 1.25rem;

	display: block;
	inline-size: var(--size);
	block-size: var(--size);
}

.ThemeToggle-icon--auto,
.ThemeToggle-icon--light {
	stroke: currentColor;
}

.ThemeToggle-icon--dark {
	fill: currentColor;
}
