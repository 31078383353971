.u-hiddenVisually {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.u-flex {
	display: flex;
}

.u-spaceBetween {
	justify-content: space-between;
}

.u-alignCenter {
	align-items: center;
}

.u-gap {
	gap: 1rem;
}

.u-container {
	margin-inline: auto;
	max-inline-size: 48em;
	padding-inline: var(--spacing);
	inline-size: 100%;
}
